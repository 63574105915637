import React from "react";
import {
    BlockTitle, Link,
    List,
    ListItem,
    Navbar,
    NavLeft, NavRight,
    NavTitle,
    Page, Popup, Searchbar, Toolbar
} from "framework7-react";
import IWAjax from "../IWAjax";
import {IWAutocomplete} from "../IWComponents";
import moment from "moment";

class ElencoOrdini extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ordini: [],
            allowInfinite: true,
            idanagrafica: 0,
            showPreloader: true,
            magazzino_selected: "",
            magazzini: [],
            data_consegna: ""
        };

        this.handleFormRicerca = this.handleFormRicerca.bind(this);
        this.setMagazzinoSelected = this.setMagazzinoSelected.bind(this);
        this.getOrdiniFromRicerca = this.getOrdiniFromRicerca.bind(this);
    }

    getOrdiniFromRicerca() {
        this.setState({
            ordini: [],
            allowInfinite: true
        });

        this.getOrdini();
    }

    getOrdini() {
        const self = this;

        if (!self.state.allowInfinite) return;
        self.setState({allowInfinite: false});

        setTimeout(() => {
            IWAjax({
                app: self.$f7,
                route: "getOrdini",
                data: {
                    index: self.state.ordini.length,
                    idanagrafica: self.state.idanagrafica,
                    idmagazzino: self.state.magazzino_selected.idmagazzino,
                    data_consegna: self.state.data_consegna
                },
                showPreloader: false,
                success: function (res) {
                    let ordini = self.state.ordini;

                    if (!res.data) {
                        self.setState({
                            allowInfinite: true,
                            ordini: [],
                            showPreloader: false
                        });
                        return;
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        let ordine = res.data[i].ordine;
                        let descr_cliente = res.data[i].descr_cliente;

                        ordine.is_preparato = parseInt(ordine.is_preparato);
                        ordini.push({
                            id: ordine.id,
                            ordine: ordine,
                            descr_cliente: descr_cliente
                        });
                    }

                    let show_preloader = ordini.length < res.data.length ;

                    self.setState({
                        ordini: ordini,
                        allowInfinite: show_preloader,
                        showPreloader: show_preloader
                    });
                }
            })
        }, 200);

    }

    handleFormRicerca(event) {
        const self = this;
        const target = event.target;
        const name = target.name;
        const value = target.type === "checkbox" ? target.checked : target.value;

        if (target.type === "autocomplete") {
            let idanagrafica = value.id;
            self.setState({
                idanagrafica: idanagrafica
            });

            return;
        }

        self.setState({
            [name]: value
        });
    }

    componentDidMount() {
        const self = this;
        const app = self.$f7;
        let idmagazzino_user = parseInt(localStorage.getItem("user_idmagazzino"));
        const magazzini = app.methods.getMagazzini();

        app.calendar.create({
            inputEl: '#data_consegna_calendar',
            openIn: 'customModal',
            dateFormat: "dd/mm/yyyy",
            closeOnSelect: true,
            monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
            monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
            dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
            dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            on: {
                dayClick: function (calendar, dayEl, year, month, day) {
                    let data_consegna = moment(day + "/" + parseInt(month + 1) + "/" + year, "DD/MM/YYYY").format("DD/MM/YYYY");

                    self.setState({
                        data_consegna: data_consegna,
                    });

                    self.getOrdiniFromRicerca();
                }
            }

        });

        self.setState({
            magazzini: magazzini,
            data_consegna: moment().format("DD/MM/YYYY")
        });

        self.setMagazzinoSelected(idmagazzino_user);

        //window.$f7 = app;
        this.getOrdini();
    }


    setMagazzinoSelected(idmagazzino){
        const self = this;
        idmagazzino = parseInt(idmagazzino);
        const magazzini = self.$f7.methods.getMagazzini();

        let ms = {idmagazzino: 0, descrizione_deposito: "Tutti"};
        if(idmagazzino > 0){
            for (let i = 0; i < magazzini.length; i++){
                let idmag = parseInt(magazzini[i].idmagazzino);
                if(idmag === idmagazzino) {
                    ms = magazzini[i];
                    break
                }
            }
        }
        self.setState({
            magazzino_selected: ms,
        });
    }

    magazzinoSelected(){
        const self = this;
        const app = self.$f7;
        const smartSelect = app.smartSelect.get('.idmagazzino');
        self.setMagazzinoSelected(smartSelect.getValue());

        self.getOrdiniFromRicerca();
    }

    handleDataConsegnaClick()
    {
        const self = this;
        self.$$("#data_consegna_calendar").click();
    }


    render() {
        const self = this;
        const {ordini} = self.state;
        const magazzini = self.state.magazzini;

        return (
            <Page
                name="ElencoOrdini"
                infinite
                infinitePreloader={this.state.showPreloader}
                onInfinite={this.getOrdini.bind(this)}
            >
                <Navbar>
                    <NavLeft backLink="Back"/>
                    <NavTitle>
                        <img src={process.env.PUBLIC_URL + '/logone_iride.png'} alt='logo' height="30px"/> Elenco Ordini
                        Cliente
                    </NavTitle>
                </Navbar>
                <Searchbar
                    disableLinkText="Cancella"
                    placeholder="Ricerca..."
                    clearButton={true}
                    searchContainer=".search-list"
                    searchIn=".item-title, .item-subtitle, .item-text"
                />
                <BlockTitle>Ordini Cliente</BlockTitle>
                <List className="search-list searchbar-found" mediaList>
                    {ordini.map((ordine, index) => (
                        <ListItem
                            key={"con_prelievo_" + index}
                            mediaItem
                            title={ordine.descr_cliente}
                            subtitle={ordine.ordine.descrizione}
                            text={ordine.ordine.note }
                            link={"/magazzino/emetti-ddt/" + ordine.id + "/" + self.props.status + "/"}
                            after={(ordine.ordine.is_preparato ? "Preparato" : "")}
                            footer={((ordine.ordine.ncolli < 16 && ordine.ordine.codice !== "E-ORD") ? " - Collettame " : (ordine.ordine.codice === "E-ORD") ? "ECOMMERCE" : "") + " - " + ordine.ordine.codice_evasione}
                        />
                    ))}
                </List>
                <Toolbar tabbar labels bottomMd>
                    <Link tabLink="#tab-1" tabLinkActive text="Cerca per Cliente" iconF7="search_strong"
                          popupOpen=".filtri-ricerca"/>

                    <Link tabLink="#tab-2" text={self.state.magazzino_selected.descrizione_deposito} iconF7="home"
                          className="idmagazzino" smartSelect smartSelectParams={{openIn: 'popup', closeOnSelect: true}}>
                        <select name="magazzini" onChange={this.magazzinoSelected.bind(this)}
                                defaultValue={self.state.magazzino_selected.idmagazzino}>
                            {magazzini.map((magazzino) => (
                                <option key={magazzino.idmagazzino}
                                        value={magazzino.idmagazzino}>{magazzino.descrizione_deposito}</option>
                            ))}
                        </select>
                    </Link>
                    <Link tabLink="#tab-3" iconF7="calendar" onClick={self.handleDataConsegnaClick.bind(this)}>
                        <input type="text" defaultValue={self.state.data_consegna} readOnly="readonly" id="data_consegna_calendar" style={{textAlign: "center", width: "auto"}}/>
                    </Link>
                </Toolbar>
                <Popup className="filtri-ricerca">
                    <Page>
                        <Navbar>
                            <NavLeft>
                                <Link popupClose>Chiudi</Link>
                            </NavLeft>
                            <NavTitle>Criteri di Ricerca</NavTitle>
                            <NavRight>
                                <Link popupClose onClick={() => self.getOrdiniFromRicerca()}>Cerca</Link>
                            </NavRight>
                        </Navbar>
                        <form id="form-ricerca">
                            <IWAutocomplete
                                title="Cliente"
                                popoverClose={true}
                                id={"typingAnagrafica"}
                                route={"autocompleteClienti"}
                                onChange={self.handleFormRicerca}
                                setItemAfter={true}
                            />
                        </form>
                    </Page>
                </Popup>
            </Page>
        );
    }
}

export default ElencoOrdini;