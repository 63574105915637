import React from 'react';
import {
    Page,
    Input,
    Block,
    BlockTitle,
    BlockHeader,
    List,
    ListItem,
    ListButton,
    NavTitle,
    Navbar
} from 'framework7-react';
import axios from "axios";
import qs from "qs";
import Config from "./Config";

class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {

        window.appLogout = function () {
            if(navigator.userAgent.includes ('wv'))
                window.location.href="http://killme";
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        document.addEventListener("keypress", this.handleKeyPress, false);
    }

    handleClick(){
        const config = new Config();
        const self = this;
        const app = self.$f7;
        const formData1 = app.form.convertToData("#form-login");
        formData1["codicecliente"] = config.codicecliente;
        const router = self.$f7router;
        app.preloader.show();
        axios({
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(formData1),
            url: config.iride_url + "/ajax.php?JSON=1&object=IWUser&tipoview=login"
        }).then(function (res) {
            app.preloader.hide();
            if(res.data.ret !== "true")
            {
                app.dialog.alert("Credenziali Errate");
                return false;
            }
            localStorage.setItem("username", self.state.username);
            localStorage.setItem("user_idmagazzino", res.data.idmagazzino);
            router.navigate("/home/");
        })
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleKeyPress(event){
        const self = this;
        if(event.which === 13 && self.state.username !== "" && self.state.password !== "") {
            self.handleClick();
        }
    }

    render() {
        return (
            <Page name="login" id="login">
                <Navbar>
                    <NavTitle><img src={process.env.PUBLIC_URL + '/irideweb_logo.png'}  alt='logo' width="100px" /></NavTitle>
                </Navbar>
                <Block>
                    <BlockHeader className="text-align-center"><img src={process.env.PUBLIC_URL + '/morellino.png'}  alt='logo'/></BlockHeader>
                    <BlockHeader>Warehouse Mobile App</BlockHeader>
                    <BlockTitle>Area Riservata</BlockTitle>
                    <form id="form-login">
                    <List>
                        <ListItem>
                            <Input label="Username" name="username" id="usename" type="text" placeholder="Username" onChange={this.handleInputChange} clearButton value={this.state.username} />
                        </ListItem>
                        <ListItem>
                            <Input label="Password" name="password" id="password" type="password" placeholder="Password" onChange={this.handleInputChange} clearButton value={this.state.password} />
                        </ListItem>
                    </List>
                    <List>
                        <ListButton type="submit" className="button button-fill" color="white" onClick={this.handleClick.bind(this)}>Login</ListButton>
                    </List>
                    </form>
                </Block>
            </Page>
        );
    }
}
export default Login;
