/**
 * Created by PhpStorm.
 * User: Daniele
 * Date: 2019-04-15
 * Time: 16:38
 */

import React from "react";
import IWAjax from "../IWAjax";
import {
    Block,
    Popup,
    BlockTitle, Link,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Page, Input, ListItem, List, Card, CardContent, Row, Col,
    PageContent, Toolbar, Sheet
} from "framework7-react";
import {IWNumpad} from "../IWComponents";
import {IWDatePicker} from "../IWComponents";
import {IWTimePicker} from "../IWComponents";
import moment from "moment";

class EmettiDdt extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ordine: {
                descrizione: "",
                n_collettame: 0,
                cliente: {
                    id: 0,
                    codice: "",
                    descrizione: ""
                },
                rows: [],
                rows_orig: [],
                ncolli: 0,
                orapartenza: "",
                note: "",
                noteprivate: ""
            },
            barcode: "",
            magazzini: [],
            modalitatrasporto: [],
            tipo_porto: [],
            aspettoesteriore: [],
            trasportatori: [],
            righe_rielaborate: [],
            is_collettame: false,
            popupOpened: false,
            page_title: "",
            rows_descrittive: [],
            q_totali_row: []
        };

        this.handleQntDDT = this.handleQntDDT.bind(this);
        this.handleBarcode = this.handleBarcode.bind(this);
        this.handleBarcodeInput = this.handleBarcodeInput.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.handleQChanged = this.handleQChanged.bind(this);

        this.handleMagazzinoChange = this.handleMagazzinoChange.bind(this);
        this.handleTrasportoChanege = this.handleTrasportoChanege.bind(this);
        this.handlePortoChange = this.handlePortoChange.bind(this);
        this.handleAspettoChange = this.handleAspettoChange.bind(this);
        this.handleVettoreChange = this.handleVettoreChange.bind(this);
        this.handleDataPartenzaChange = this.handleDataPartenzaChange.bind(this);
        this.handleOrarioPartenzaChange = this.handleOrarioPartenzaChange.bind(this);

        this.showWarningQ = this.showWarningQ.bind(this);
    }

    componentDidMount() {
        const self = this;
        IWAjax({
            app: self.$f7,
            route: "getDettaglioOrdine",
            data: {idordine: self.props.idordine},
            success: function (res) {
                const ordine = res.data.ordine;
                ordine.is_preparato = parseInt(ordine.is_preparato);
                const magazzini = res.data.magazzini;
                const aspettoesteriore = res.data.dati_aggiuntivi.aspettoesteriore;
                const tipo_porto = res.data.dati_aggiuntivi.tipo_porto;
                const modalitatrasporto = res.data.dati_aggiuntivi.modalitatrasporto;
                const trasportatori = res.data.dati_aggiuntivi.trasportatori;
                ordine.rows_orig = ordine.rows;
                ordine.rows.map(row => {
                    row.q_tot = row.q === "0" ? 0 : parseFloat(row.q);
                    row.q_tot_omaggio = row.q_omaggio1 === "0" ? 0 : parseFloat(row.q_omaggio1);
                    row.q = 0;
                    row.q_omaggio1 = 0;
                    row.giacenza_lotto = "";
                    row.q_impegnata = "";

                    return row;
                });

                if(ordine.datapartenza === "")
                    ordine.datapartenza = moment().format("YYYY/MM/DD");
                if(ordine.orapartenza === "")
                    ordine.orapartenza = moment().format("HH.mm");

                const is_collettame = ordine.ncolli < 16;

                ordine.datapartenza = moment(ordine.datapartenza.toString(), "YYYY/MM/DD").format("DD/MM/YYYY");
                let page_title = "Preparazione Ordine";

                if(ordine.codice_evasione === null || ordine.codice_evasione === "")
                    ordine.codice_evasione = "DDTV";

                if (self.props.status !== "4") {
                    page_title = "Emissione " + ordine.codice_evasione;
                    ordine.data_consegna = moment().format("DD/MM/YYYY");
                }

                self.setState({
                    ordine: ordine,
                    is_collettame: is_collettame,
                    magazzini: magazzini,
                    aspettoesteriore: aspettoesteriore,
                    tipo_porto: tipo_porto,
                    trasportatori: trasportatori,
                    modalitatrasporto: modalitatrasporto,
                    page_title: page_title,
                });

                self.rielaboraRighe(ordine.rows);

                self.$f7.smartSelect.get('.idmagazzino a').setValue(ordine.id_magazzinosorgente);

                const tsm = self.$f7.smartSelect.get('.trasporto a');
                tsm.params["openIn"] = "popup";
                tsm.params["routableModals"] = false;
                tsm.setValue(ordine.idmodalitatrasporto);

                const psm = self.$f7.smartSelect.get('.porto a');
                psm.params["openIn"] = "popup";
                psm.params["routableModals"] = false;
                psm.setValue(ordine.porto);

                const asm = self.$f7.smartSelect.get('.aspetto a');
                asm.params["openIn"] = "popup";
                asm.params["routableModals"] = false;
                asm.setValue(ordine.aspettoesteriore);

                const vsm = self.$f7.smartSelect.get('.vettore a');
                vsm.params["openIn"] = "popup";
                vsm.params["routableModals"] = false;
                vsm.setValue(ordine.idvettore);

            }
        });
        document.addEventListener('paste', self.handlePaste);
    }

    handlePaste(e) {
        const self = this;
        if (e.target.name !== "barcode") {
            let clipboardData = e.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData('Text');
            self.handleBarcode(pastedData);
        }
    }

    handleQntDDT(event) {
        const target = event.target;
        const value = !target.value ? 0 : target.value;
        let name = target.name;
        const self = this;

        const is_omaggio = !name.startsWith("qnt");

        let info = !is_omaggio ? name.replace("qnt_", "").split("_") : name.replace("omaggio_", "").split("_");
        let idarticolo = parseInt(info[0]);
        let index = parseInt(info[1]);

        const righe_rielaborate = self.state.righe_rielaborate;
        index = righe_rielaborate[idarticolo][index].index;

        let ordine = self.state.ordine;

        if(!is_omaggio)
            ordine.rows[index].q = value;
        else
            ordine.rows[index].q_omaggio1 = value;

        self.setState({
            ordine: ordine
        });
    }


    handleBarcodeInput(event) {
        const self = this;
        const target = event.target;
        const barcode = target.value;
        self.handleBarcode(barcode);
    }

    handleBarcode(barcode) {
        const self = this;
        /*const target = event.target;
        const barcode = target.value;*/

        if (barcode.length === 12)
            barcode = "0" + barcode;

        self.setState({
            barcode: barcode
        });

        if (!barcode || barcode.length !== 13) return;

        const ordine = self.state.ordine;

        IWAjax({
            app: self.$f7,
            route: "getInfoFromBarcode",
            data: {barcode: barcode, idordine: ordine.id, idmagazzino: ordine.id_magazzinosorgente},
            success: function (res) {
                let row = res.data.row;
                let articolo = res.data.articolo;
                let giac_lotto = res.data.giacenza_lotto;
                let q_impegnata = res.data.q_impegnata;

                let q = parseInt(articolo.pzcollo) === 0 ? 1 : articolo.pzcollo;

                let found = false;
                let new_lotto = false;
                let row_old_lotto = null;
                ordine.rows.map(riga => {
                    if (riga.idarticolo === articolo.id) {
                        if(!riga.lotto || riga.lotto === row.lotto) {
                            if (riga.q >= riga.q_tot && riga.q_tot_omaggio > 0)
                                riga.q_omaggio1 = parseFloat(parseFloat(riga.q_omaggio1) + parseFloat(q));
                            else
                                riga.q = parseFloat(parseFloat(riga.q) + parseFloat(q));
                            riga.lotto = row.lotto;
                            riga.giacenza_lotto = giac_lotto ? parseFloat(giac_lotto) : "";
                            riga.q_impegnata = giac_lotto ? parseFloat(q_impegnata) : "";
                            found = true;
                            return riga;
                        } else {
                           new_lotto = true;
                           row_old_lotto = riga;
                           row_old_lotto.idriga_collegata = riga.id;
                        }
                    }
                    return riga;
                });

                if (!found ) {
                    if(new_lotto && giac_lotto > 0) {
                        let new_row = JSON.parse(JSON.stringify(row_old_lotto));

                        new_row.id = 0;
                        new_row.idarticolo = articolo.id;
                        new_row.codicearticolo = articolo.codice;
                        new_row.commento = articolo.descrizione;
                        new_row.q = articolo.pzcollo;
                        new_row.lotto = row.lotto;
                        new_row.giacenza_lotto = parseFloat(giac_lotto);
                        new_row.q_impegnata = parseFloat(q_impegnata);
                        //q_tot: parseFloat(row_old_lotto.q_tot),
                        //q_tot_omaggi: parseFloat(row_old_lotto.q_tot_omaggi)
                        ordine.rows.push(new_row);
                    } else {
                        self.$f7.toast.create({
                            text: 'Articolo Non Trovato',
                            closeTimeout: 2000,
                        }).open();
                    }
                }

                self.setState({
                    ordine: ordine,
                    barcode: ""
                });

                self.rielaboraRighe(ordine.rows);
            }
        });
    }

    handleQChanged() {
        const self = this;
        const rows = self.state.ordine.rows;
        self.rielaboraRighe(rows);
    }

    handleMagazzinoChange() {
        const self = this;
        const app = self.$f7;
        const smartSelect = app.smartSelect.get('.idmagazzino a');
        const ordine = self.state.ordine;
        ordine.id_magazzinosorgente = parseFloat(smartSelect.getValue());

        self.setState({
            ordine: ordine
        });
    }

    handleTrasportoChanege() {
        const self = this;
        const app = self.$f7;
        const smartSelect = app.smartSelect.get('.trasporto a');
        const ordine = self.state.ordine;
        ordine.trasporto = parseFloat(smartSelect.getValue());

        self.setState({
            ordine: ordine
        });
    }

    handlePortoChange() {
        const self = this;
        const app = self.$f7;
        const smartSelect = app.smartSelect.get('.porto a');
        const ordine = self.state.ordine;
        ordine.porto = parseFloat(smartSelect.getValue());

        self.setState({
            ordine: ordine
        });
    }

    handleAspettoChange() {
        const self = this;
        const app = self.$f7;
        const smartSelect = app.smartSelect.get('.aspetto a');
        const ordine = self.state.ordine;
        ordine.aspetto = parseFloat(smartSelect.getValue());

        self.setState({
            ordine: ordine
        });
    }

    handleVettoreChange() {
        const self = this;
        const app = self.$f7;
        const smartSelect = app.smartSelect.get('.vettore a');
        const ordine = self.state.ordine;
        ordine.idvettore = parseFloat(smartSelect.getValue());

        self.setState({
            ordine: ordine
        });
    }

    handleDataPartenzaChange(calendar) {
        const self = this;
        const ordine = self.state.ordine;
        ordine.datapartenza = calendar.target.value.format("DD/MM/YYYY");

        self.setState({
            ordine: ordine
        });
    }

    handleOrarioPartenzaChange(picker, value, displayValue) {
        const self = this;
        const ordine = self.state.ordine;
        ordine.orapartenza = value.join(".");

        self.setState({
            ordine: ordine
        });
    }

    handleNcolliChange(numpad) {
        const self = this;
        const ordine = self.state.ordine;
        ordine.ncolli = parseFloat(numpad.target.value);

        self.setState({
            ordine: ordine
        });
    }

    handleNcollettameChange(numpad) {
        const self = this;
        const ordine = self.state.ordine;
        ordine.n_collettame = parseInt(numpad.target.value);

        self.setState({
            ordine: ordine
        });
    }

    handleDataDocumentoChange(calendar){
        const self = this;
        const ordine = self.state.ordine;
        let data_doc = calendar.target.value.format("YYYY/MM/DD");

        ordine.regdata = data_doc;
        ordine.docdata = data_doc;

        self.setState({
            ordine: ordine
        });
    }

    rielaboraRighe(rows) {
        const self = this;
        let righe_descrittive = self.state.rows_descrittive.length === 0 ? [] : self.state.rows_descrittive;
        let righe_rielaborate = [];

        let idarticolo, row;
        let q_totali = [];

        const ordine = self.state.ordine;

        var alert_msg = "";
        const ordine_rows = [];
        let index = 0;

        const righe_coll = [];
        const idrighe = [];

        for (let i = 0; i < rows.length; i++) {
            row = rows[i];
            idarticolo = row.idarticolo;

            let commento = row.commento.toLowerCase();
            if (self.state.is_collettame && commento.indexOf("contrassegnato con il n") > 0) {
                const regex = /[\d]+/gmi;
                let res_arr = regex.exec(row.commento);
                let n_collettame = parseInt(res_arr[0]);

                if (!isNaN(n_collettame) && n_collettame > 0) {
                    ordine.n_collettame = n_collettame;
                    self.setState({
                        ordine: ordine
                    })
                }
            }

            if (parseInt(idarticolo) === 0 || !idarticolo || row.idcategoria_articolo === 386) {
                const regex = /(\w+)/gm;
                let is_commento = regex.test(row.commento);

                if (is_commento)
                    righe_descrittive.push(row);
                continue;
            }
            //if (parseInt(row.idarticolo) === 0) continue;

            if (ordine.is_preparato && (parseFloat(row.q2) > 0 || parseFloat(row.q_omaggio2) > 0)) {
                row.q = parseFloat(row.q2);
                row.q_omaggio1 = parseFloat(row.q_omaggio2);
                row.q2 = 0;
                row.q_omaggio2 = 0;
            }

            if (!q_totali[row.idarticolo]) {
                q_totali[row.idarticolo] = {
                    "q_tot": 0,
                    "q_tot_omaggi": 0,
                    index: row.index,
                    q_tot_row: 0,
                    q_tot_omaggi_row: 0
                };
            }

            if (parseFloat(row.q) > (parseFloat(row.giacenza_lotto) + parseFloat(row.q_impegnata)) && row.giacenza_lotto !== "") {
                //self.showWarningQ(row, 'Quantità non sufficiente per articolo');
                alert_msg += 'Quantità non sufficiente per articolo <b>' + row.codicearticolo + " - " + row.lotto + "</b><br/>"
            }

            q_totali[row.idarticolo]["q_tot"] += parseInt(row.q);
            q_totali[row.idarticolo]["q_tot_omaggi"] += !isNaN(row.q_omaggio1) ? parseInt(row.q_omaggio1) : 0;
            //console.log(q_totali[row.idarticolo]["q_tot_row"], q_totali[row.idarticolo]["q_tot_omaggi_row"]);
            if (row.id > 0) {
                q_totali[row.idarticolo]["q_tot_row"] += parseInt(row.q_tot);
                q_totali[row.idarticolo]["q_tot_omaggi_row"] += parseInt(row.q_tot_omaggio);
            }

            if (q_totali[row.idarticolo]["q_tot_omaggi_row"] > 0 || q_totali[row.idarticolo]["q_tot_row"] > 0) {

                /*if(row.q_tot === 0)
                    row.q_tot = q_totali[row.idarticolo]["q_tot_row"];

                if(row.q_tot_omaggio === 0)
                    row.q_tot_omaggio = q_totali[row.idarticolo]["q_tot_omaggi_row"];*/

                row.css_q = row.css_o = "q_value ";
                if (parseFloat(q_totali[row.idarticolo]["q_tot"]) > parseFloat(q_totali[row.idarticolo]["q_tot_row"])) {
                    row.css_q += "q_yellow";
                    //self.showWarningQ(row, 'Superata Quantità Totale Riga Ordine');
                    alert_msg += 'Superata Quantità Totale Riga Ordine <b>' + row.codicearticolo + " - " + row.lotto + "</b><br/>";
                } else if (q_totali[row.idarticolo]["q_tot"] < parseFloat(q_totali[row.idarticolo]["q_tot_row"])) {
                    row.css_q += "q_red";
                } else
                    row.css_q += "q_green";

                if (parseFloat(q_totali[row.idarticolo]["q_tot_omaggi"]) > parseFloat(q_totali[row.idarticolo]["q_tot_omaggi_row"])) {
                    row.css_o += "q_yellow";
                    alert_msg += 'Superata Quantità Omaggio Totale Riga Ordine <b>' + row.codicearticolo + " - " + row.lotto + "</b><br/>";
                } else if (q_totali[row.idarticolo]["q_tot_omaggi"] < parseFloat(q_totali[row.idarticolo]["q_tot_omaggi_row"])) {
                    row.css_o += "q_red";
                } else
                    row.css_o += "q_green";
            }

            if (row.lotto !== "" && (Number(row.q) + Number(row.q_omaggio1)) === 0) continue;

            ordine_rows.push(row);

            if (!righe_rielaborate.hasOwnProperty(idarticolo)) righe_rielaborate[idarticolo] = [];
            row.index = index;
            righe_rielaborate[idarticolo].push(row);
            if(row.idriga_collegata)
                righe_coll.push(row.idriga_collegata);
            idrighe.push(row.id);
            index++;
        }

        righe_coll.forEach((coll) => {
            if(!idrighe.includes(coll)){
                const riga_ord = ordine.rows_orig.filter((r => r.id === coll))[0];
                ordine_rows.forEach((ro, i) =>{
                    if(ro.idriga_collegata === coll) {
                        ordine_rows[i].id = coll;
                        ordine_rows[i].q_tot = riga_ord.q_tot;
                        ordine_rows[i].q_tot_omaggi_row = riga_ord.q_tot_omaggi_row;
                        self.rielaboraRighe(ordine_rows);
                        return false;
                    }
                });
            }
        });

        ordine.rows = ordine_rows;

        self.setState({
            righe_rielaborate: righe_rielaborate,
            rows_descrittive: righe_descrittive,
            q_totali_row: q_totali,
            ordine: ordine,
        }, () => console.log(self.state));

        if (alert_msg !== "") {
            self.$f7.dialog.create({
                title: 'IrideWeb - Attenzione!',
                text: alert_msg,
                cssClass: "iw-dialog",
                buttons: [
                    {
                        text: 'Ok',
                    }
                ],
            }).open();
        }
    }

    showWarningQ(row, msg){
        const self = this;
        self.$f7.dialog.create({
            title: 'IrideWeb - Attenzione!',
            text: msg + ' <b>' + row.codicearticolo + " - " + row.lotto + "</b>",
            cssClass: "iw-dialog",
            buttons: [
                {
                    text: 'Ok',
                }
            ],
        }).open();
    }

    saveDocument() {
        const self = this;
        if (self.props.status === "4") {
            self.saveDDT();
        } else {
            self.setState({popupOpened: true});
        }
    }

    saveDDT() {
        const self = this;
        let route = "aggiornaOrdine";

        if (self.props.status !== "4")
            route = "saveDocumentoCollegato";
        else {
            //Un magazziniere lo vuole uno no per ora commentato
            /*self.state.righe_rielaborate.map((rows) => {
                let msg = "";
                rows.map((row) => {
                    if(row.idarticolo && row.idarticolo > 0) {
                        if (row.css_o && row.css_o.indexOf("q_red") > -1) {
                            msg += "Quantità Omaggio non corrisposndente alla Quantità Omaggio Ordinata<br>";
                        }
                        if (row.css_q && row.css_q.indexOf("q_red") > -1) {
                            msg += "Quantità non corrisposndente alla Quantità Ordinata<br>";
                        }
                        self.showWarningQ(row, msg);
                    }
                })
            });*/
        }

        IWAjax({
            app: self.$f7,
            route: route,
            data: {ordine: self.state.ordine},
            success: function (res) {
                if (res.data.ret) {
                    self.$f7.dialog.create({
                        title: 'IrideWeb',
                        text: "Operazione Effettuata Con Successo",
                        cssClass: "iw-dialog",
                        buttons: [
                            {
                                text: 'Stampa',
                            },
                            {
                                text: 'Ok',
                            }
                        ],
                        onClick: function (dialog, index) {
                            //stampa
                            if (index === 0 && res.data.info.print) {
                                window.open(res.data.info.print);
                            }
                            self.$f7router.navigate("/home/")
                        }
                    }).open();
                } else {
                    self.$f7.dialog.create({
                        title: 'IrideWeb - Errore',
                        text: res.data.msg + " <br/> Errore, Operazione Non Effettuata",
                        cssClass: "iw-dialog",
                        buttons: [
                            {
                                text: 'Ok',
                            }
                        ],
                    }).open();
                }
            }
        })
    }

    componentWillUnmount() {
        const self = this;
        document.removeEventListener('paste', self.handlePaste);
    }

    render() {
        const self = this;
        const {ordine, righe_rielaborate, magazzini, rows_descrittive, q_totali_row} = self.state;
        const aspettoesteriore = self.state.aspettoesteriore;
        const tipo_porto = self.state.tipo_porto;
        const trasportatori = self.state.trasportatori;
        const modalitatrasporto = self.state.modalitatrasporto;
        const app = self.$f7;

        return (
            <Page name="EmettiDdt">
                <Navbar>
                    <NavLeft backLink="Back"/>
                    <NavTitle>
                        <img src={process.env.PUBLIC_URL + '/logone_iride.png'} alt='logo' height="30px"/> {self.state.page_title}
                    </NavTitle>
                    <NavRight>
                        <Link onClick={this.saveDocument.bind(this)}>Salva</Link>
                    </NavRight>
                </Navbar>
                <Block className="no-margin">
                    <form onSubmit={e => {
                        e.preventDefault();
                    }}>
                        <List inset className="no-margin">
                            <ListItem>
                                <div className="item-title" style={{fontWeight:"bold"}}>{ordine.descrizione}</div>
                            </ListItem>
                            <ListItem>
                                <div className="item-title">
                                    <div className="item-header"><b>Cliente:</b></div>
                                    <div slot="after-title" style={{whiteSpace:"initial", fontSize:"0.9rem"}}>
                                        <Link popupOpen=".indirizzo_popup">{"[" + ordine.cliente.codice + "] " + ordine.cliente.descrizione}</Link>
                                    </div>
                                </div>
                            </ListItem>

                            <ListItem className={ordine.note === "" ? "display-none" : "bg-color-yellow"}>
                                <div className="item-title">
                                    <div className="item-header"><b>Note:</b></div>
                                    <div slot="after-title" style={{whiteSpace:"initial", fontSize:"0.9rem"}}>{ordine.note}</div>
                                </div>
                            </ListItem>
                            <ListItem className={ordine.noteprivate === "" ? "display-none" : ""}>
                                <div className="item-title">
                                    <div className="item-header"><b>Note Private:</b></div>
                                    <div slot="after-title" style={{whiteSpace:"initial", fontSize:"0.9rem"}}>{ordine.noteprivate}</div>
                                </div>
                            </ListItem>
                            <ListItem title="Magazzino" className="idmagazzino" smartSelect>
                                <select name="magazzini" onChange={self.handleMagazzinoChange}
                                        value={self.state.ordine.id_magazzinosorgente}>
                                    {magazzini.map((magazzino) => (
                                        <option key={magazzino.idmagazzino}
                                                value={magazzino.idmagazzino}>{magazzino.descrizione_deposito}</option>
                                    ))}
                                </select>
                            </ListItem>
                            <ListItem title="Data Documento" className={self.props.status === "4" ? "display-none" : ""}>
                                <IWDatePicker name="data_documento" value={[self.state.ordine.data_consegna]}
                                              onChange={self.handleDataDocumentoChange.bind(this)}/>
                            </ListItem>
                            <ListItem title="Contrassegnato Con Il Numero:" className={self.state.is_collettame ? "" : "display-none"}>
                                <IWNumpad name="n_collettame"
                                          value={self.state.ordine.n_collettame}
                                          onChange={self.handleNcollettameChange.bind(this)}
                                          />
                            </ListItem>
                            <ListItem title="N. Colli"  className={self.props.status !== "4" ? "display-none" : ""}>
                                <IWNumpad name="ncolli_ord"
                                          value={self.state.ordine.ncolli}
                                          onChange={self.handleNcolliChange.bind(this)}
                                          style={{textAlign: "right"}}/>
                            </ListItem>
                            <ListItem className={app.methods.isAndroidWebView() ? "display-none" : ""}>
                                <Input autofocus={true} label="Barcode" placeholder="Barcode" type="text" id="barcode"
                                       name="barcode" onChange={self.handleBarcodeInput} clearButton/>
                            </ListItem>
                        </List>

                        <BlockTitle className={rows_descrittive.length === 0 ? "display-none" : ""}>Righe Descrittive</BlockTitle>
                        <Card key={"list_desctittive"}>
                            <List>
                                {rows_descrittive.map((r, i) => (
                                    <ListItem key={"item_desc_" + i}>
                                        <div className="item-title">
                                            <div slot="after-title" style={{whiteSpace:"initial", fontSize:"0.9rem"}}>{r.commento}</div>
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                        </Card>

                        <BlockTitle>Articoli</BlockTitle>
                        {righe_rielaborate.map((rows, idarticolo) => (
                            <Card key={"list_" + idarticolo}
                                  title={rows[0].codicearticolo + " - " + rows[0].commento}
                                  popoverOpen={".popupEditRicambio" + idarticolo}
                                  swipeout
                                  link="#">
                                <CardContent>
                                    <Block>
                                        <Row>
                                            <Col><span className="q_label">Qnt. venduta:</span><span
                                                className={rows[(rows.length - 1)].css_q}>{parseFloat(q_totali_row[idarticolo].q_tot_row)}</span></Col>
                                        </Row>
                                        <Row className="margin-top">
                                            <Col><span className="q_label">Qnt. omaggio:</span><span
                                                className={rows[(rows.length - 1)].css_o}>{parseFloat(q_totali_row[idarticolo].q_tot_omaggi_row)}</span></Col>
                                        </Row>
                                    </Block>
                                    <List style={{marginTop: "0.5rem"}}>
                                        <ListItem style={{textAlign: "center", fontWeight: "bold", fontSize: "0.9rem"}}>
                                            <span style={{width: "35%"}}>Lotto</span>
                                            <span style={{width: "25%"}}>Giac.</span>
                                            <span style={{width: "20%"}}>Q. Vend.</span>
                                            <span style={{width: "20%"}}>Q. Omg.</span>
                                        </ListItem>
                                        {rows.map((row, i) => (
                                            <ListItem key={"row_" + i}>
                                                <span style={{width: "35%"}}>{row.lotto}</span>
                                                <span
                                                    style={{width: "25%"}}>{row.giacenza_lotto + " (" + row.q_impegnata + ")"}</span>
                                                <IWNumpad label="Qnt." name={"qnt_" + idarticolo + "_" + i}
                                                          value={row.q} onChange={self.handleQntDDT}
                                                          onClose={self.handleQChanged}
                                                          style={{textAlign: "right"}}
                                                          wsize="20%"
                                                />
                                                <IWNumpad label="Qnt.Om" name={"omaggio_" + idarticolo + "_" + i}
                                                          value={row.q_omaggio1} onChange={self.handleQntDDT}
                                                          onClose={self.handleQChanged}
                                                          style={{textAlign: "right"}}
                                                          wsize="20%"
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        ))}
                        <Popup className="datiaggiuntivi-popup" opened={self.state.popupOpened}
                               onPopupClosed={() => this.setState({popupOpened: false})}>
                            <Page>
                                <Navbar>
                                    <NavLeft>
                                        <Link popupClose>Annulla</Link>
                                    </NavLeft>
                                    <NavTitle>Dati Accompagnatori</NavTitle>
                                    <NavRight>
                                        <Link popupClose onClick={this.saveDDT.bind(this)}>Salva</Link>
                                    </NavRight>
                                </Navbar>
                                <Block>
                                    <List>
                                        <ListItem title="Trasporto" smartSelect className="trasporto">
                                            <select name="trasporto" value={self.state.ordine.trasporto}
                                                    onChange={self.handleTrasportoChanege}>
                                                {
                                                    Object.keys(modalitatrasporto).map((mt) => (
                                                        <option key={mt}
                                                                value={mt}>{modalitatrasporto[mt]}</option>
                                                    ))
                                                }
                                            </select>
                                        </ListItem>

                                        <ListItem title="Porto" smartSelect className="porto">
                                            <select name="porto" value={self.state.ordine.porto}
                                                    onChange={self.handlePortoChange}>
                                                {
                                                    Object.keys(tipo_porto).map((tp) => (
                                                        <option key={tp}
                                                                value={tp}>{tipo_porto[tp]}</option>
                                                    ))
                                                }
                                            </select>
                                        </ListItem>

                                        <ListItem title="Aspetto Esteriore Beni" smartSelect className="aspetto">
                                            <select name="aspetto" value={self.state.ordine.aspetto}
                                                    onChange={self.handleAspettoChange}>
                                                {
                                                    Object.keys(aspettoesteriore).map((ae) => (
                                                        <option key={ae}
                                                                value={ae}>{aspettoesteriore[ae]}</option>
                                                    ))
                                                }
                                            </select>
                                        </ListItem>

                                        <ListItem title="Vettore" smartSelect className="vettore">
                                            <select name="vettore" value={self.state.ordine.vettore}
                                                    onChange={self.handleVettoreChange}>
                                                {
                                                    Object.keys(trasportatori).map((trasp) => (
                                                        <option key={trasp}
                                                                value={trasp}>{trasportatori[trasp]}</option>
                                                    ))
                                                }
                                            </select>
                                        </ListItem>

                                        <ListItem title="Data Partenza">
                                            <IWDatePicker name="data_partenza" value={[self.state.ordine.datapartenza]}
                                                          onChange={self.handleDataPartenzaChange}/>
                                        </ListItem>

                                        <ListItem title="Ora Partenza">
                                            <IWTimePicker name="ora_partenza" value={ordine.orapartenza}
                                                          onChange={self.handleOrarioPartenzaChange}/>
                                        </ListItem>
                                        <ListItem title="N. Colli">
                                            <IWNumpad name="ncolli"
                                                      value={self.state.ordine.ncolli}
                                                      onChange={self.handleNcolliChange.bind(this)}
                                                      style={{textAlign: "right"}}/>
                                        </ListItem>

                                    </List>
                                </Block>
                            </Page>
                        </Popup>
                        <Sheet className="indirizzo_popup">
                            <Toolbar>
                                <div className="right">
                                    <Link sheetClose>Chiudi</Link>
                                </div>
                            </Toolbar>
                            <PageContent>
                                <Block>
                                    <p>{ordine.indirizzo_destinazione === "" ? ordine.indirizzo_destinatario : ordine.indirizzo_destinazione}</p>
                                </Block>
                            </PageContent>
                        </Sheet>
                    </form>
                </Block>
            </Page>
        )
    }
}

export default EmettiDdt;