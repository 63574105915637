import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Framework7React from 'framework7-react';
import Framework7Keypad from 'framework7-plugin-keypad';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'framework7/css/framework7.bundle.min.css';
import 'framework7-plugin-keypad/dist/framework7.keypad.min.css';

Framework7.use(Framework7React);
Framework7.use(Framework7Keypad);

ReactDOM.render(<App />, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
