import React from 'react';
import {
    Page,
    Navbar,
    Block,
    BlockTitle,
    List,
    ListButton,
    NavTitle,
    NavLeft,
    NavRight,
    Link,
    ListItem, Panel
} from 'framework7-react';
import IWAjax from "./IWAjax";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            buttons: [
                {key: 4, title: "Preparazione Ordine", url: "/magazzino/ordini/"},
                {key: 3, title: "Emetti DDT / Fattura", url: "/magazzino/ordini/"},
            ],
            global_theme: localStorage.getItem("global-theme"),
            color_theme: localStorage.getItem("color-theme")
        };

        this.changeGlobalTheme = this.changeGlobalTheme.bind(this);
        this.changeColorTheme = this.changeColorTheme.bind(this);
    }

    UNSAFE_componentWillMount() {
        document.getElementById("app").className = localStorage.getItem("global-theme") + " " + localStorage.getItem("color-theme");
    }

    componentDidMount() {
        const self = this;
        const app = self.$f7;
        self.logout = self.logout.bind(this);
        app.methods.hideSoftKeyboard();

        window.appLogout = function () {
            self.logout(true);
        };

        IWAjax({
            app: self.$f7,
            route: "getMagazzini",
            data: {},
            success: function (res) {
                localStorage.setItem("magazzini", JSON.stringify(res.data.magazzini));
            }
        });

    }

    logout(close) {
        const self = this;
        const app = self.$f7;
        app.dialog.confirm("Confermare?", "Logout", function () {
            localStorage.setItem("username", "");
            app.panel.get("left").destroy();
            if (close && app.methods.isAndroidWebView())
                window.location.href = "http://killme";
            else
                self.$f7router.navigate("/login/");
        });
    }

    changeGlobalTheme(event) {
        const self = this;
        const target = event.target;
        const value = target.value;

        self.setState({
            global_theme: value
        });

        document.getElementById("app").className = value + " " + self.state.color_theme;

        localStorage.setItem("global-theme", value);
    }

    changeColorTheme(event) {
        const self = this;
        const target = event.target;
        const value = target.value;

        self.setState({
            color_theme: value
        });

        document.getElementById("app").className = self.state.global_theme + " " + value;
        localStorage.setItem("color-theme", value);
    }

    render() {
        const self = this;
        const {buttons} = self.state;

        const global_themes = [
            {theme: "", title: "Standard"},
            {theme: "theme-dark", title: "Dark"},
        ];

        const colors = [
                {theme: "", title: "Standard"},
                {theme: "red", title: "Rosso"},
                {theme: "green", title: "Verde"},
                {theme: "blue", title: "Blu"},
                {theme: "pink", title: "Rosa"},
                {theme: "yellow", title: "Giallo"},
                {theme: "orange", title: "Arancione"},
                {theme: "purple", title: "Viola"},
                {theme: "deeppurple", title: "Viola scuro"},
                {theme: "lightblue", title: "Azzurro"},
                {theme: "teal", title: "Verde Acqua"},
                {theme: "lime", title: "Lime"},
                {theme: "deeporange", title: "Arancione scuro"},
                {theme: "gray", title: "Grigio"},
                {theme: "white", title: "Bianco"},
                {theme: "black", title: "Nero"}
            ]

        ;
        return (
            <Page name="home">
                <Panel left reveal>
                    <BlockTitle>Impostazioni</BlockTitle>
                    <BlockTitle>Tema grafico</BlockTitle>
                    <List>
                        {global_themes.map((color, index) => (
                            <ListItem
                                key={"key_global_theme_" + index}
                                radio
                                name="tema-radio"
                                value={color.theme}
                                title={color.title}
                                onChange={self.changeGlobalTheme}
                                defaultChecked={color.theme === self.state.global_theme}
                            />
                        ))}
                    </List>
                    <BlockTitle>Tema Colori</BlockTitle>
                    <List style={{height: "calc(100% - 22em)", overflow: "auto"}}>
                        {colors.map((color, index) => (
                            <ListItem
                                key={"key_color_theme_" + index}
                                radio
                                name="color-radio"
                                value={"color-theme-" + color.theme}
                                title={color.title}
                                onChange={self.changeColorTheme}
                                defaultChecked={color.theme === self.state.color_theme}
                            />
                        ))}
                    </List>

                    <BlockTitle>
                        Morellino Wharehouse<br/>
                        WebView: {self.$f7.methods.androidAppVersion()}
                    </BlockTitle>

                </Panel>
                <Navbar>
                    <NavLeft><Link iconF7="menu" panelOpen="left"/></NavLeft>
                    <NavTitle><img src={process.env.PUBLIC_URL + '/irideweb_logo.png'} alt='logo'
                                   width="100px"/></NavTitle>
                    <NavRight><Link onClick={() => self.logout(false)} iconOnly iconF7="exit" link="#"/></NavRight>
                </Navbar>
                <Block>
                    <BlockTitle>Seleziona un'attività</BlockTitle>
                </Block>
                <List>
                    {buttons.map(button => (
                        <ListButton style={{borderBottom: "1px solid #dedede"}} key={button.key} title={button.title} href={button.url + button.key + "/"}/>
                    ))}
                </List>
            </Page>
        );
    }
}

export default Home;