function Config() {
    this.codicecliente = "morellino";
    if(window.location.port === "3000")
        this.iride_url = "http://irideweb";
    else
        this.iride_url = "https://www.irideglobalservice.it";
    this.api_url = this.iride_url + "/api/webapps/morellino-app/";
    this.client_secret = "UOWZIGLQTEKFYMBX";
}

export default Config;