import React from "react";
import {Input, List, ListItem} from "framework7-react";
import moment from "moment";
import IWAjax from "./IWAjax";

export class IWDatePicker extends React.Component{

    componentDidMount(){
        const self = this;
        const app = self.$f7;
        let openin = self.props.openIn ? self.props.openIn : 'auto';
        app.calendar.create({
            inputEl: '#' + self.props.name,
            dateFormat: "dd/mm/yyyy",
            closeOnSelect: true,
            openIn: openin,
            monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
            monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
            dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
            dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            on: {
                dayClick: function (calendar, dayEl, year, month, day) {
                    if(typeof self.props.onChange === "function") {
                        self.props.onChange({
                            target: {
                                calendar: calendar,
                                dayEl: dayEl,
                                value: moment(day + "/" + parseInt(month + 1) + "/" + year, "DD/MM/YYYY"),
                                name: self.props.name,
                                type: "datepicker"
                            }
                        });
                    }
                }
            }
        });
    }

    render(){
        return (
            <div>
                {this.props.label}
                <ul>
                    <li>
                        <input ref={this.props.name} type="text" placeholder={this.props.placeholder} readOnly="readonly" id={this.props.name} onChange={this.props.onChange} name={this.props.name} value={this.props.value} style={{borderBottom: "1px #dedede solid", maxWidth: "10em" }}/>
                    </li>
                </ul>
            </div>
        )
    }
}


export class IWTimePicker extends React.Component{

    componentDidMount(){
        const self = this;
        const app = self.$f7;
        app.picker.create({
            inputEl: '#' + self.props.name,
            rotateEffect: true,
            formatValue: function (values, displayValues) {
                return values[0] + '.' + values[1];
            },
            cols: [
                // Hours
                {
                    values: (function () {
                        var arr = [];
                        for (var i = 0; i <= 23; i++) { arr.push(i < 10 ? '0' + i : i); }
                        return arr;
                    })(),
                },
                // Divider
                {
                    divider: true,
                    content: '.'
                },
                // Minutes
                {
                    values: (function () {
                        var arr = [];
                        for (var i = 0; i <= 59; i++) { arr.push(i < 10 ? '0' + i : i); }
                        return arr;
                    })(),
                }
            ],
            on: {
                change : function (picker, value, displayValue) {
                    if (value[0] !== "00" && value[1] !== "00")
                        self.props.onChange(picker, value, displayValue);
                },
                open: function (picker) {
                    picker.setValue(self.props.value.split("."));
                }
            }
        });
    }

    render(){
        return (
            <div>
                {this.props.label}
                <ul>
                    <li>
                        <input type="text" placeholder={this.props.placeholder} readOnly="readonly" id={this.props.name} onChange={this.props.onChange} name={this.props.name} value={this.props.value} style={{borderBottom: "1px #dedede solid", maxWidth: "10em"}}/>
                    </li>
                </ul>
            </div>
        )
    }
}

export class IWNumpad extends React.Component{

    componentDidMount(){
        const self = this;
        const app = self.$f7;

        app.keypad.create({
            inputEl: "#" + self.props.name,
            toolbarCloseText: "Fine",
            formatValue: function (value) {
                self.props.onChange({
                    target: {
                        value: value,
                        name: self.props.name,
                        type: "numpad"
                    }
                });
            },
            on: {
                close(keypad) {
                    if (typeof self.props.onClose === "function"){
                        self.props.onClose();
                    }
                },
                open(keypad){
                    keypad.value = "";
                }
            }
        });
    }

    render(){
        let wsize = this.props.wsize ? this.props.wsize : 'auto';
        return (
            <span style={{width:wsize, maxWidth: "4rem"}}>
                <Input label={this.props.label} type="text" className="numpad" id={this.props.name} errorMessage="Compilare una quantità valida!" name={this.props.name} value={this.props.value} required validate pattern="[0-9]+([\.,][0-9]+)?" onChange={this.props.onChange} onClose={this.props.onClose} style={{textAlign:"right"}} readonly/>
            </span>
        );
    }
}

export class IWAutocomplete extends React.Component{
    componentDidMount(){
        const self = this;
        const app = self.$f7;

        app.autocomplete.create({
            openIn: 'popup',
            openerEl: '#' + self.props.id,
            valueProperty: 'id',
            textProperty: 'text',
            limit: 50,
            expandInput: true,
            closeOnSelect: true,
            source: function (query, render) {
                const autocomplete = this;
                let results = [];
                if (query.length === 0) {
                    render(results);
                    return;
                }
                // Show Preloader
                autocomplete.preloaderShow();

                IWAjax({
                    app: app,
                    data: {
                        query: query
                    },
                    showPreloader: false,
                    route: self.props.route,
                    success: function (res) {
                        autocomplete.preloaderHide();
                        if(!res.data) return false;
                        for (let i = 0; i < res.data.length; i++) {
                            if (res.data[i].text.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(res.data[i]);
                        }
                        render(results);
                    }
                })
            },
            on:{
                change: function (value) {
                    self.props.onChange({
                        target: {
                            value: value[0],
                            name: self.props.id,
                            type: "autocomplete"
                        }
                    });
                    if(self.props.onChanged){
                        let params = self.props.onChangedParams;
                        if(!params) params = [];
                        params.id = value[0].id;
                        self.props.onChanged(params);
                    }

                    if(self.props.setItemAfter)
                        document.getElementById(self.props.id).getElementsByClassName("item-after")[0].textContent = value[0].descrizione;
                }
            }
        })
    }

    render(){
        return (
            <List>
                <ListItem id={this.props.id} title={this.props.title} link="#" popoverClose={this.props.popoverClose} after={this.props.setItemAfter ? "Cerca" : ""}/>
            </List>
        )
    }
}