import React from 'react';
import {App, View} from 'framework7-react';
import 'framework7-icons';
import Login from './Login.js';
import Home from "./Home";
import ElencoOrdini from "./Magazzino/ElencoOrdini";
import EmettiDdt from "./Magazzino/EmettiDdt";

const f7params = {
    theme: 'auto',
    name: "IrideWEB Mobile",
    id: 'com.morellinoapp.iride',
    view: {
        pushState: true,
        pushStateSeparator: '',
        pushStateOnLoad: false
    },
    routes: [
        {
            path: '/login/',
            component: Login,
            on: {
                pageAfterIn(e, page) {
                    if(page.router.history.length > 1)
                        page.router.clearPreviousHistory();
                },
            }
        },
        {
            path: '/home/',
            component: Home,
            on: {
                pageAfterIn(e, page) {
                    if(page.router.history.includes("/login/"))
                        page.router.clearPreviousHistory();
                },
            }
        },
        {
            path: "/magazzino/ordini/:status/",
            component: ElencoOrdini
        },
        {
            path: "/magazzino/emetti-ddt/:idordine/:status/",
            component: EmettiDdt
        }
    ],
    methods: {
        hideSoftKeyboard: function () {
            setTimeout(function () {
                try {
                    /*eslint-disable no-undef*/
                    Android.hideKeyboard();
                    /*eslint-enable no-undef*/
                } catch (e) {
                    return false;
                }
            }, 500);
        },
        androidAppVersion: function () {
            try {
                /*eslint-disable no-undef*/
                return Android.appVersion();
                /*eslint-enable no-undef*/
            } catch (e) {
                return false;
            }
        },
        isAndroidWebView() {
            return navigator.userAgent.includes('wv');
        },
        getMagazzini(){
            return JSON.parse(localStorage.getItem("magazzini"));
        }
    }
};

const username = localStorage.getItem("username");
const token = localStorage.getItem("access_token");

export default () => (
    <App params={f7params}>
        <View main url={username && token ? "/home/" : "/login/"}/>
    </App>
);